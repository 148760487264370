// Smaller font size
@media (max-width: 650px) {

    body, input, textarea {
        font-size: 13px;
    }
    #branding {
        #title-wrapper {
            padding: 1.5em 1em;
            text-align: center;
        }
        #site-title {
            a {
                font-size: 24px;
            }
        }
        #site-logo {
            display: none;
        }
        #menu table {
            table-layout: fixed;
            width: 100%;
            font-size: 12px;
            padding: 0;
            td {
                text-align: center;
                a {
                    padding: 0;
                    background-size: 24px 24px;
                    background-repeat: no-repeat;
                    background-position: center;
                    &.icon::before {
                        display: block;
                        font-size: 24px;
                    }
                }
                &.menu-item-active .icon::before {
                    color: #a6d71c;
                }
                a.icon-image::before {
                    content: "\a0";
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 20px;
                }
                // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="54" width="70" version="1.1"><g transform="matrix(0.1,0,0,-0.1,0,51)"><path style="fill:#111111;stroke:none" d="M 567.488,128.809 C 688.312,297.461 516.918,506.387 365.539,478.062 200.43,447.168 33.4688,247.367 187.367,129.156 342.047,10.4023 446,129.035 478.707,198.941 c 4.824,10.309 7.395,14.45 7.82,14.239 2.45,-1.207 -66.941,-147.8284 -188.531,-88.403 C 155.613,193.16 175.469,440.047 422.77,450.426 577.641,456.922 607.875,264.23 500.961,131.184 434.438,48.0273 177.844,-18.4961 85.1836,138.312 -16.8047,310.906 187.348,508.949 187.348,508.949 23.2109,387.211 -38.0156,201.766 47.1719,102.672 163.586,-32.75 453.445,-30.3789 567.488,128.809"/><path style="fill:#111;stroke:none" d="M 356.82,113.395 271.906,391.605 558.234,329.309 356.82,113.395"/></g></svg>');
                // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="54" width="70" version="1.1"><g transform="matrix(0.1,0,0,-0.1,0,51)"><path style="fill:#1982d1;stroke:none" d="M 567.488,128.809 C 688.312,297.461 516.918,506.387 365.539,478.062 200.43,447.168 33.4688,247.367 187.367,129.156 342.047,10.4023 446,129.035 478.707,198.941 c 4.824,10.309 7.395,14.45 7.82,14.239 2.45,-1.207 -66.941,-147.8284 -188.531,-88.403 C 155.613,193.16 175.469,440.047 422.77,450.426 577.641,456.922 607.875,264.23 500.961,131.184 434.438,48.0273 177.844,-18.4961 85.1836,138.312 -16.8047,310.906 187.348,508.949 187.348,508.949 23.2109,387.211 -38.0156,201.766 47.1719,102.672 163.586,-32.75 453.445,-30.3789 567.488,128.809"/><path style="fill:#1982d1;stroke:none" d="M 356.82,113.395 271.906,391.605 558.234,329.309 356.82,113.395"/></g></svg>');
                // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="54" width="70" version="1.1"><g transform="matrix(0.1,0,0,-0.1,0,51)"><path style="fill:#a6d71c;stroke:none" d="M 567.488,128.809 C 688.312,297.461 516.918,506.387 365.539,478.062 200.43,447.168 33.4688,247.367 187.367,129.156 342.047,10.4023 446,129.035 478.707,198.941 c 4.824,10.309 7.395,14.45 7.82,14.239 2.45,-1.207 -66.941,-147.8284 -188.531,-88.403 C 155.613,193.16 175.469,440.047 422.77,450.426 577.641,456.922 607.875,264.23 500.961,131.184 434.438,48.0273 177.844,-18.4961 85.1836,138.312 -16.8047,310.906 187.348,508.949 187.348,508.949 23.2109,387.211 -38.0156,201.766 47.1719,102.672 163.586,-32.75 453.445,-30.3789 567.488,128.809"/><path style="fill:#fff200;stroke:none" d="M 356.82,113.395 271.906,391.605 558.234,329.309 356.82,113.395"/></g></svg>');
                a.icon-logo::before {
                    background-size: 30px;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTQiIHdpZHRoPSI3MCIgdmVyc2lvbj0iMS4xIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgwLjEsMCwwLC0wLjEsMCw1MSkiPjxwYXRoIHN0eWxlPSJmaWxsOiMxMTE7c3Ryb2tlOm5vbmUiIGQ9Ik0gNTY3LjQ4OCwxMjguODA5IEMgNjg4LjMxMiwyOTcuNDYxIDUxNi45MTgsNTA2LjM4NyAzNjUuNTM5LDQ3OC4wNjIgMjAwLjQzLDQ0Ny4xNjggMzMuNDY4OCwyNDcuMzY3IDE4Ny4zNjcsMTI5LjE1NiAzNDIuMDQ3LDEwLjQwMjMgNDQ2LDEyOS4wMzUgNDc4LjcwNywxOTguOTQxIGMgNC44MjQsMTAuMzA5IDcuMzk1LDE0LjQ1IDcuODIsMTQuMjM5IDIuNDUsLTEuMjA3IC02Ni45NDEsLTE0Ny44Mjg0IC0xODguNTMxLC04OC40MDMgQyAxNTUuNjEzLDE5My4xNiAxNzUuNDY5LDQ0MC4wNDcgNDIyLjc3LDQ1MC40MjYgNTc3LjY0MSw0NTYuOTIyIDYwNy44NzUsMjY0LjIzIDUwMC45NjEsMTMxLjE4NCA0MzQuNDM4LDQ4LjAyNzMgMTc3Ljg0NCwtMTguNDk2MSA4NS4xODM2LDEzOC4zMTIgLTE2LjgwNDcsMzEwLjkwNiAxODcuMzQ4LDUwOC45NDkgMTg3LjM0OCw1MDguOTQ5IDIzLjIxMDksMzg3LjIxMSAtMzguMDE1NiwyMDEuNzY2IDQ3LjE3MTksMTAyLjY3MiAxNjMuNTg2LC0zMi43NSA0NTMuNDQ1LC0zMC4zNzg5IDU2Ny40ODgsMTI4LjgwOSIvPjxwYXRoIHN0eWxlPSJmaWxsOiMxMTE7c3Ryb2tlOm5vbmUiIGQ9Ik0gMzU2LjgyLDExMy4zOTUgMjcxLjkwNiwzOTEuNjA1IDU1OC4yMzQsMzI5LjMwOSAzNTYuODIsMTEzLjM5NSIvPjwvZz48L3N2Zz4K');
                }
                &:hover a.icon-logo, a.icon-logo:focus {
                    &::before {
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTQiIHdpZHRoPSI3MCIgdmVyc2lvbj0iMS4xIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgwLjEsMCwwLC0wLjEsMCw1MSkiPjxwYXRoIHN0eWxlPSJmaWxsOiMxOTgyZDE7c3Ryb2tlOm5vbmUiIGQ9Ik0gNTY3LjQ4OCwxMjguODA5IEMgNjg4LjMxMiwyOTcuNDYxIDUxNi45MTgsNTA2LjM4NyAzNjUuNTM5LDQ3OC4wNjIgMjAwLjQzLDQ0Ny4xNjggMzMuNDY4OCwyNDcuMzY3IDE4Ny4zNjcsMTI5LjE1NiAzNDIuMDQ3LDEwLjQwMjMgNDQ2LDEyOS4wMzUgNDc4LjcwNywxOTguOTQxIGMgNC44MjQsMTAuMzA5IDcuMzk1LDE0LjQ1IDcuODIsMTQuMjM5IDIuNDUsLTEuMjA3IC02Ni45NDEsLTE0Ny44Mjg0IC0xODguNTMxLC04OC40MDMgQyAxNTUuNjEzLDE5My4xNiAxNzUuNDY5LDQ0MC4wNDcgNDIyLjc3LDQ1MC40MjYgNTc3LjY0MSw0NTYuOTIyIDYwNy44NzUsMjY0LjIzIDUwMC45NjEsMTMxLjE4NCA0MzQuNDM4LDQ4LjAyNzMgMTc3Ljg0NCwtMTguNDk2MSA4NS4xODM2LDEzOC4zMTIgLTE2LjgwNDcsMzEwLjkwNiAxODcuMzQ4LDUwOC45NDkgMTg3LjM0OCw1MDguOTQ5IDIzLjIxMDksMzg3LjIxMSAtMzguMDE1NiwyMDEuNzY2IDQ3LjE3MTksMTAyLjY3MiAxNjMuNTg2LC0zMi43NSA0NTMuNDQ1LC0zMC4zNzg5IDU2Ny40ODgsMTI4LjgwOSIvPjxwYXRoIHN0eWxlPSJmaWxsOiMxOTgyZDE7c3Ryb2tlOm5vbmUiIGQ9Ik0gMzU2LjgyLDExMy4zOTUgMjcxLjkwNiwzOTEuNjA1IDU1OC4yMzQsMzI5LjMwOSAzNTYuODIsMTEzLjM5NSIvPjwvZz48L3N2Zz4K');
                    }
                }
                &.menu-item-active a.icon-logo::before {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTQiIHdpZHRoPSI3MCIgdmVyc2lvbj0iMS4xIj48ZyB0cmFuc2Zvcm09Im1hdHJpeCgwLjEsMCwwLC0wLjEsMCw1MSkiPjxwYXRoIHN0eWxlPSJmaWxsOiNhNmQ3MWM7c3Ryb2tlOm5vbmUiIGQ9Ik0gNTY3LjQ4OCwxMjguODA5IEMgNjg4LjMxMiwyOTcuNDYxIDUxNi45MTgsNTA2LjM4NyAzNjUuNTM5LDQ3OC4wNjIgMjAwLjQzLDQ0Ny4xNjggMzMuNDY4OCwyNDcuMzY3IDE4Ny4zNjcsMTI5LjE1NiAzNDIuMDQ3LDEwLjQwMjMgNDQ2LDEyOS4wMzUgNDc4LjcwNywxOTguOTQxIGMgNC44MjQsMTAuMzA5IDcuMzk1LDE0LjQ1IDcuODIsMTQuMjM5IDIuNDUsLTEuMjA3IC02Ni45NDEsLTE0Ny44Mjg0IC0xODguNTMxLC04OC40MDMgQyAxNTUuNjEzLDE5My4xNiAxNzUuNDY5LDQ0MC4wNDcgNDIyLjc3LDQ1MC40MjYgNTc3LjY0MSw0NTYuOTIyIDYwNy44NzUsMjY0LjIzIDUwMC45NjEsMTMxLjE4NCA0MzQuNDM4LDQ4LjAyNzMgMTc3Ljg0NCwtMTguNDk2MSA4NS4xODM2LDEzOC4zMTIgLTE2LjgwNDcsMzEwLjkwNiAxODcuMzQ4LDUwOC45NDkgMTg3LjM0OCw1MDguOTQ5IDIzLjIxMDksMzg3LjIxMSAtMzguMDE1NiwyMDEuNzY2IDQ3LjE3MTksMTAyLjY3MiAxNjMuNTg2LC0zMi43NSA0NTMuNDQ1LC0zMC4zNzg5IDU2Ny40ODgsMTI4LjgwOSIvPjxwYXRoIHN0eWxlPSJmaWxsOiNmZmYyMDA7c3Ryb2tlOm5vbmUiIGQ9Ik0gMzU2LjgyLDExMy4zOTUgMjcxLjkwNiwzOTEuNjA1IDU1OC4yMzQsMzI5LjMwOSAzNTYuODIsMTEzLjM5NSIvPjwvZz48L3N2Zz4K');
                }
                // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30"><ellipse cx="10" cy="10" rx="10" ry="10" fill="#111"/><ellipse cx="20" cy="10" rx="10" ry="10" fill="#111"/><ellipse cx="10" cy="20" rx="10" ry="10" fill="#111"/><ellipse cx="20" cy="20" rx="10" ry="10" fill="#111"/><ellipse cx="15" cy="15" rx="5" ry="5" fill="#fff"/></svg>');
                // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30"><ellipse cx="10" cy="10" rx="10" ry="10" fill="#1982d1"/><ellipse cx="20" cy="10" rx="10" ry="10" fill="#1982d1"/><ellipse cx="10" cy="20" rx="10" ry="10" fill="#1982d1"/><ellipse cx="20" cy="20" rx="10" ry="10" fill="#1982d1"/><ellipse cx="15" cy="15" rx="5" ry="5" fill="#fff"/></svg>');
                a.icon-floralswing::before {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGVsbGlwc2UgY3g9IjEwIiBjeT0iMTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTExIi8+PGVsbGlwc2UgY3g9IjIwIiBjeT0iMTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTExIi8+PGVsbGlwc2UgY3g9IjEwIiBjeT0iMjAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTExIi8+PGVsbGlwc2UgY3g9IjIwIiBjeT0iMjAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTExIi8+PGVsbGlwc2UgY3g9IjE1IiBjeT0iMTUiIHJ4PSI1IiByeT0iNSIgZmlsbD0iI2ZmZiIvPjwvc3ZnPgo=');
                }
                &:hover a.icon-floralswing, a.icon-floralswing:focus {
                    &::before {
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGVsbGlwc2UgY3g9IjEwIiBjeT0iMTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTk4MmQxIi8+PGVsbGlwc2UgY3g9IjIwIiBjeT0iMTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTk4MmQxIi8+PGVsbGlwc2UgY3g9IjEwIiBjeT0iMjAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTk4MmQxIi8+PGVsbGlwc2UgY3g9IjIwIiBjeT0iMjAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjMTk4MmQxIi8+PGVsbGlwc2UgY3g9IjE1IiBjeT0iMTUiIHJ4PSI1IiByeT0iNSIgZmlsbD0iI2ZmZiIvPjwvc3ZnPgo=');
                    }
                }
                &.menu-item-active a.icon-floralswing::before {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PGVsbGlwc2UgY3g9IjEwIiBjeT0iMTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjYTZkNzFjIi8+PGVsbGlwc2UgY3g9IjIwIiBjeT0iMTAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjYTZkNzFjIi8+PGVsbGlwc2UgY3g9IjEwIiBjeT0iMjAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjYTZkNzFjIi8+PGVsbGlwc2UgY3g9IjIwIiBjeT0iMjAiIHJ4PSIxMCIgcnk9IjEwIiBmaWxsPSIjYTZkNzFjIi8+PGVsbGlwc2UgY3g9IjE1IiBjeT0iMTUiIHJ4PSI1IiByeT0iNSIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
                }
                .menu-item-text {
                    display: none;
                }
            }
        }
    }
    #primary.layout-start article {
        .left, .right {
            float: none;
        }
        .large {
            width: 100%;
        }
        .floating-image {
            width: 100%;
            max-width: 150px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    #primary.layout-about article .about-flex {
        display: block;
        .about-main {
            border-left: none;
        }
        .about-image-bottom {
            padding: 0;
            width: 100%;
        }
    }
    article .entry-title {
        font-size: 25px;
    }
    article .entry-content .floralswing {
        float: none;
        margin: 0 auto;
    }
}
